import { createRouter, createWebHistory } from 'vue-router'

import i18n from '../i18n'

import Home from '../views/Home.vue'
import Quiz from '../views/Quiz.vue'
import About from '../views/About.vue'
// import App from '../App.vue'

const router = createRouter({
  history: createWebHistory(),
  //base: '/fuzetea-quiz/',
  routes: [
    // {
    //   path: '/',
    //   name: 'root',
    //   redirect: process.env.VUE_APP_I18N_LOCALE,
    // },
    {
      path: '/:locale',
      name: 'root',
      // path: `/(pl|en)`,
      component: {
        template: "<router-view></router-view>"
      },

      children: [
        {
          path: 'quiz',
          name: 'Quiz',
          component: Quiz, props: true
        },
        {
          path: '',
          name: 'Home',
          component: Home, props: true
        },
        {
          path: 'about',
          name: 'About',
          component: About, props: true

        }
      ]
    },
    {
      path: '/:notFound(.*)',
      redirect() {

        // console.log('routing-redirect notFound')
        // console.log('process.env.VUE_APP_I18N_LOCALE: ' + process.env.VUE_APP_I18N_LOCALE)
        return process.env.VUE_APP_I18N_LOCALE;
      }
    }
  ]
})


router.beforeEach(function (to, from, next) {
  // console.dir(to);
  // console.log('beforeEnter from:' + from);

  const locale = to.params.locale; // 1
  // console.log('locale:' + locale);

  const supported_locales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(','); // 2
  if (!supported_locales.includes(locale)) return next('hu'); // 3

  if (i18n.global.locale !== locale) {
    i18n.global.locale = locale;
  }
  return next() // 5
});



export default router
