export default {
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fedezd fel a fúzió erejét, és játssz a nyereményekért!"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meríts inspirációt a Fuzetea különleges ízfúzióiból! Fedezd fel a saját fúziód és játssz velünk az értékes nyereményekért!"])},
    "btnQuiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kezdjük!"])}
  },
  "quiz": {
    "btnPlayAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Játssz újra"])},
    "btnWatchVideoAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nézd meg újra a fúziódat!"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tovább"])},
    "copyInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hívd meg barátaidat is, <br />hogy fedezzék fel<br />a fúziójukat!"])},
    "copyLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link másolása"])},
    "copyValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Én már felfedeztem, miben rejlik a fúzió és mi tesz azzá, ami vagyok. Te is kíváncsi vagy? Játssz a Fuzetea-vel! Keresd fel a fuzetea.coca-cola.hu oldalt!"])},
    "justCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sikeres másolás!"])},
    "questions": {
      "q1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["És a rémálmod?"])},
      "q2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi az álmod?"])},
      "q3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi az őrült vágyad?"])}
    },
    "nameForm": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az egyik fontos alkotóelemed: a neved. "])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add meg a kezdéshez:"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "answers": {
      "q1-opt1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["veszekedés"])},
      "q1-opt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lámpaláz"])},
      "q1-opt3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["képmutatás"])},
      "q1-opt4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mérgező kapcsolatok"])},
      "q1-opt5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zuhanás"])},
      "q1-opt6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["magány"])},
      "q1-opt7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["társadalmi nyomás"])},
      "q1-opt8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["túlórázás"])},
      "q1-opt9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["szakítások"])},
      "q1-opt10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unalom"])},
      "q2-opt1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["igaz szerelem"])},
      "q2-opt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lelki béke"])},
      "q2-opt3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["saját start-up</br> vállalkozás"])},
      "q2-opt4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["még több kaland"])},
      "q2-opt5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["meghódítani </br>a nagyszínpadot"])},
      "q2-opt6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["feljutni </br>a ranglétra tetejére"])},
      "q2-opt7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["egy csodás család"])},
      "q2-opt8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["igaz barátság"])},
      "q2-opt9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jövőre nyugdíj"])},
      "q2-opt10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["változást elérni </br>a világban"])},
      "q3-opt1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["világkörüli </br>út egy scooteren"])},
      "q3-opt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lefutni a maratont"])},
      "q3-opt3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["híressé válni"])},
      "q3-opt4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vígjátékot írni"])},
      "q3-opt5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["úszni az összes óceánban"])},
      "q3-opt6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zenélni a kedvenc </br>bandámmal"])},
      "q3-opt7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["űrutazás"])},
      "q3-opt8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["az álomvárosomban élni"])},
      "q3-opt9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bungee jumping"])},
      "q3-opt10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["szafarira menni"])}
    },
    "results": {
      "madeOfFusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fúzió ereje"])},
      "discoverFuzetea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fedezd fel a fúzió erejét a <a href='https://fuzetea.coca-cola.hu/' target='_blank'>www.fuzetea.coca-cola.hu</a> oldalon!"])}
    }
  },
  "error": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoppá…"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valami hiba történt"])}
  }
}