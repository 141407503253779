export default {
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover what makes you,<br /> you and win prizes"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get inspired by Fuzetea’s own fusions<br /> and play the game to win prizes that fit your fusion."])},
    "btnQuiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start your journey"])}
  },
  "quiz": {
    "btnPlayAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play again"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "copyInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy the video link<br />and share it<br />with your friends"])},
    "copyLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy link"])},
    "copyValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy Value"])},
    "justCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy successfuly"])},
    "questions": {
      "q1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What's your</br>fear?"])},
      "q2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What's your</br>dream?"])},
      "q3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What's your</br>crazy wish?"])}
    },
    "nameForm": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your name is part of what makes you, you."])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spell it bellow:"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*You may enter name or nickname. No data will be stored"])}
    },
    "answers": {
      "q1-opt1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fights"])},
      "q1-opt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stage fright"])},
      "q1-opt3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hypocrisy"])},
      "q1-opt4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["toxic relationships"])},
      "q1-opt5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["falling"])},
      "q1-opt6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["loneliness"])},
      "q1-opt7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["social pressure"])},
      "q1-opt8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["overwork"])},
      "q1-opt9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["break-ups"])},
      "q1-opt10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["boredom"])},
      "q2-opt1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["true love"])},
      "q2-opt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["find peace"])},
      "q2-opt3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["my own start-up"])},
      "q2-opt4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["more adventure"])},
      "q2-opt5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the big stage"])},
      "q2-opt6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["make it to the top"])},
      "q2-opt7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["an amazing family"])},
      "q2-opt8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["true friendship"])},
      "q2-opt9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["retire next year"])},
      "q2-opt10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["make a diference"])},
      "q3-opt1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["world tour on a scooter"])},
      "q3-opt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["run a marathon"])},
      "q3-opt3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["became famous"])},
      "q3-opt4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["write comedy"])},
      "q3-opt5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["swim in all oceans"])},
      "q3-opt6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["play with my favourite band"])},
      "q3-opt7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["travel in space"])},
      "q3-opt8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["live in my dream city"])},
      "q3-opt9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bungee jump"])},
      "q3-opt10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["go on a safari"])}
    },
    "results": {
      "madeOfFusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["made of fusion"])},
      "discoverFuzetea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["discover what makes you, on <a href='https://www.fuze-tea.com' target='_blank'>coca-cola.com/fuzetea</a>"])}
    }
  },
  "error": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An Error occured!"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something failed!"])}
  }
}