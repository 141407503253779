<template>
  <header class="row">
    <img src="../assets/header-1600x700.jpg" />
  </header>

  <div class="main row home">
    <h2 class="title" v-html="$t('home.title')"></h2>
    <p class="subtitle" v-html="$t('home.subtitle')"></p>

    <router-link class="button primary" :to="{ name: 'Quiz', params: { locale: this.$i18n.locale } }"> {{ $t("home.btnQuiz") }} </router-link>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  watch: {
    $route() {
      // react to route changes...
    },
  },
};
</script>
