<template>
  <!-- <LocaleSwitcher />
  <MainMenu /> -->

  <router-view />
</template>
<script>
// import LocaleSwitcher from "./components/LocaleSwitcher.vue";
// import MainMenu from "./components/MainMenu.vue";

export default {
  //components: { LocaleSwitcher },
  components: {},
  // created() {
  //   console.log("created: this.$i18n.locale: " + this.$i18n.locale);
  // },
};
</script>
<style>
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/
</style>
