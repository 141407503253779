import { createApp } from 'vue'

import App from './App.vue'
import VueClipboard from 'vue3-clipboard'



// import "bootstrap/dist/css/bootstrap.min.css";
import './assets/reset.css';
import './assets/style.css';
import i18n from './i18n'
import router from './router'
import BaseSpinner from './components/ui/BaseSpinner.vue';
import BaseDialog from './components/ui/BaseDialog.vue';

const app = createApp(App);
app.use(i18n);
app.use(router);
app.use(VueClipboard, {
  autoSetContainer: true,
  appendToBody: true,
})
app.component('base-spinner', BaseSpinner);
app.component('base-dialog', BaseDialog);


app.mount('#app');

