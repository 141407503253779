export default {
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odkryj, co sprawia,</br> że jesteś sobą i wygraj nagrody"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zainspiruj się własnymi fuzjami Fuzetea i zagraj w grę,</br> aby wygrać nagrody, które pasują do Twojej fuzji."])},
    "btnQuiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zacznij swoją przygodę!"])}
  },
  "quiz": {
    "btnPlayAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zagraj ponownie"])},
    "btnWatchVideoAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobacz ponownie"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Następne"])},
    "copyInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skopiuj link wideo<br />i udostępnij go<br /> znajomym"])},
    "copyLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skopiuj link"])},
    "copyValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy Value"])},
    "justCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy successfuly"])},
    "questions": {
      "q1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(*)What's your</br>fear?"])},
      "q2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(*)What's your</br>dream?"])},
      "q3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(*)What's your</br>crazy wish?"])}
    },
    "nameForm": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twoje imię jest częścią tego, co sprawia, że ​​jesteś."])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przeliteruj to poniżej:"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Możesz wpisać imię lub pseudonim. Żadne dane nie będą przechowywaned"])}
    },
    "answers": {
      "q1-opt1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(*)fights"])},
      "q1-opt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(*)stage fright"])},
      "q1-opt3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(*)hypocrisy"])},
      "q1-opt4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(*)toxic relationships"])},
      "q1-opt5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(*)falling"])},
      "q1-opt6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(*)loneliness"])},
      "q1-opt7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(*)social pressure"])},
      "q1-opt8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(*)overwork"])},
      "q1-opt9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(*)break-ups"])},
      "q1-opt10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(*)boredom"])},
      "q2-opt1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(*)true love"])},
      "q2-opt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(*)find peace"])},
      "q2-opt3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(*)my own start-up"])},
      "q2-opt4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(*)more adventure"])},
      "q2-opt5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(*)the big stage"])},
      "q2-opt6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(*)make it to the top"])},
      "q2-opt7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(*)an amazing family"])},
      "q2-opt8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(*)true friendship"])},
      "q2-opt9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(*)retire next year"])},
      "q2-opt10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(*)make a diference"])},
      "q3-opt1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(*)world tour on a scooter"])},
      "q3-opt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(*)run a marathon"])},
      "q3-opt3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(*)became famous"])},
      "q3-opt4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(*)write comedy"])},
      "q3-opt5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(*)swim in all oceans"])},
      "q3-opt6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(*)play with my favourite band"])},
      "q3-opt7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(*)travel in space"])},
      "q3-opt8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(*)live in my dream city"])},
      "q3-opt9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(*)bungee jump"])},
      "q3-opt10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(*)go on a safari"])}
    },
    "results": {
      "madeOfFusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["made of fusion"])},
      "discoverFuzetea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["discover what makes you, on <a href='https://www.fuze-tea.com' target='_blank'>coca-cola.com/fuzetea</a>"])}
    }
  },
  "error": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upsss błąd"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niestety wystapił nieoczekiwany wyjątek :("])}
  }
}