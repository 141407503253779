<template>
  <base-dialog :show="!!showInfo" :title="info.title" :buttonText="info.btnText" @close="closeInfo">
    <p>
      {{ info.message }}
    </p>
  </base-dialog>
  <div v-if="isLoading">
    <base-spinner></base-spinner>
  </div>
  <div class="main row quiz">
    <p v-if="questionIndex === -1" class="description">{{ $t("quiz.nameForm.description") }}</p>
    <div v-if="questionIndex === -1" v-bind:key="questionIndex" class="questionContainer">
      <h2 class="titleContainer title">{{ $t("quiz.nameForm.title") }}</h2>
      <p class="subtitle">{{ $t("quiz.nameForm.subtitle") }}</p>
      <div class="optionContainer">
        <input v-model="userName" />
      </div>
      <footer class="questionFooter">
        <nav class="pagination" role="navigation" aria-label="pagination">
          <a v-if="questionIndex > 0" class="button prev" :class="questionIndex < 1 ? 'disabled' : ''" v-on:click="prev()" :disabled="questionIndex < 1"></a>
          <router-link v-else class="button prev" :to="{ name: 'Home', params: { locale: this.$i18n.locale } }"></router-link>
          <a class="button next" :class="userName == null ? 'disabled' : ''" v-on:click="next()" :disabled="userName != null">
            <span>{{ $t("quiz.next") }}</span>
          </a>
        </nav>
      </footer>
    </div>
    <div v-else-if="questionIndex < questions.length" v-bind:key="questionIndex" class="questionContainer">
      <h2 class="titleContainer title" v-html="questions[questionIndex].text"></h2>
      <div class="optionContainer">
        <div
          class="option"
          v-for="(response, index) in questions[questionIndex].responses"
          @click="selectOption(index)"
          :class="{ 'is-selected': userResponses[questionIndex] == index }"
          :key="index"
        >
          <span v-html="response.text"></span>
        </div>
      </div>
      <footer class="questionFooter">
        <nav class="pagination" role="navigation" aria-label="pagination">
          <a class="button prev" @click="prev()"></a>
          <a
            class="button next"
            :class="userResponses[questionIndex] == null ? 'disabled' : ''"
            v-on:click="next()"
            :disabled="questionIndex >= questions.length"
          >
            <span>{{ $t("quiz.next") }}</span>
          </a>
        </nav>
        <ul class="progress">
          <li v-for="(question, index) in questions" :key="index" :class="[index <= questionIndex ? 'active' : '']"></li>
        </ul>
      </footer>
    </div>
    <!-- 
    v-show="questionIndex < questions.length"  -->
    <div v-show="questionIndex >= questions.length" class="quizCompleted has-text-centered">
      <div class="video-container">
        <div class="player" v-show="videoIdx < 4" :style="[videoIdx === 1 ? { zIndex: 5 } : { zIndex: 1 }]">
          <video
            ref="vid1"
            :playsinline="true"
            autoplay
            muted
            preload="metadata"
            @ended="videoEnded1"
            :src="this.userResponses[0] !== null ? require('@/assets/videos/' + this.questions[0].responses[this.userResponses[0]].movie) : null"
          ></video>
          <p v-show="videoIdx === 1" class="video-title" v-html="videoText1"></p>
        </div>
        <div class="player" v-show="videoIdx < 4" :style="[videoIdx === 2 ? { zIndex: 5 } : { zIndex: 1 }]">
          <video
            ref="vid2"
            :playsinline="true"
            autoplay
            muted
            preload="metadata"
            @ended="videoEnded2"
            :src="this.userResponses[1] !== null ? require('@/assets/videos/' + this.questions[1].responses[this.userResponses[1]].movie) : null"
          ></video>
          <p v-show="videoIdx === 2" class="video-title" v-html="videoText2"></p>
        </div>
        <div class="player" v-show="videoIdx < 4" :style="[videoIdx === 3 ? { zIndex: 5 } : { zIndex: 1 }]">
          <video
            ref="vid3"
            :playsinline="true"
            autoplay
            muted
            preload="metadata"
            @ended="videoEnded3"
            :src="this.userResponses[2] !== null ? require('@/assets/videos/' + this.questions[2].responses[this.userResponses[2]].movie) : null"
          ></video>
          <p v-show="videoIdx === 3" class="video-title" v-html="videoText3"></p>
        </div>
        <div class="player" v-show="videoIdx === 4" :style="[videoIdx === 4 ? { zIndex: 5 } : { zIndex: 1 }]">
          <div class="content">
            <div class="userName">{{ userName }}</div>
            <div class="img">
              <div
                :style="[
                  userResponses[0] !== null
                    ? { backgroundImage: 'url(' + require('@/assets/' + questions[0].responses[userResponses[0]].img) + ')' }
                    : { backgroundImage: 'none' },
                ]"
              ></div>

              <div
                :style="[
                  userResponses[1] !== null
                    ? { backgroundImage: 'url(' + require('@/assets/' + questions[1].responses[userResponses[0]].img) + ')' }
                    : { backgroundImage: 'none' },
                ]"
              ></div>
              <div
                :style="[
                  userResponses[2] !== null
                    ? { backgroundImage: 'url(' + require('@/assets/' + questions[2].responses[userResponses[2]].img) + ')' }
                    : { backgroundImage: 'none' },
                ]"
              ></div>
            </div>
            <h3>{{ $t("quiz.results.madeOfFusion") }}</h3>
            <p v-html="$t('quiz.results.discoverFuzetea')"></p>
          </div>
        </div>
      </div>
      <div class="video-footer">
        <div>
          <p v-html="$t('quiz.copyInfo')"></p>
          <a
            class="button xs copy tooltip"
            :data-tooltip="tooltip"
            data-flow="bottom"
            v-clipboard:copy="$t('quiz.copyValue')"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            >{{ $t("quiz.copyLink") }}
          </a>
        </div>
        <div>
          <a @click="watchVideoAgain" class="button xs" v-show="videoIdx === 4">{{ $t("quiz.btnWatchVideoAgain") }}</a>
          <router-link class="button xs secondary" :to="{ name: 'Home', params: { locale: this.$i18n.locale } }"> {{ $t("quiz.btnPlayAgain") }} </router-link>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  computed: {
    videoText1: {
      get() {
        // console.log(this.userResponses[0]);
        if (this.userResponses[0] !== null) {
          return this.questions[0].responses[this.userResponses[0]].text;
        }
        return null;
      },
    },
    videoText2: {
      get() {
        // console.log(this.userResponses[1]);
        if (this.userResponses[1] !== null) {
          return this.questions[1].responses[this.userResponses[1]].text;
        }
        return null;
      },
    },
    videoText3: {
      get() {
        // console.log(this.userResponses[2]);
        if (this.userResponses[2] !== null) {
          return this.questions[2].responses[this.userResponses[2]].text;
        }
        return null;
      },
    },
  },
  data() {
    return {
      isLoading: false,
      showInfo: false,
      info: {
        title: "",
        message: "",
        btnText: "",
      },

      questionIndex: -1,
      locale: "",
      questions: [],
      userResponses: [],
      userName: null,
      tooltip: null,
      players: {},
      videoIdx: 0,
      videoLoaded: 0,
    };
  },
  watch: {
    $route() {
      // react to route changes...
      this.localizeQuestion();
    },
  },
  mounted() {
    // this.testInit();
  },
  methods: {
    restart: function () {
      this.questionIndex = -1;
      this.userResponses = Array(this.questions.length).fill(null);
    },
    selectOption: function (index) {
      this.userResponses[this.questionIndex] = index;
    },

    next: function () {
      if (this.questionIndex === -1) {
        if (this.userName !== null) this.questionIndex++;
        return;
      } else if (this.questionIndex < this.questions.length && this.userResponses[this.questionIndex] !== null) {
        if (this.questionIndex === 0) {
          this.$refs.vid1.load();
          this.$refs.vid1.play();
        } else if (this.questionIndex === 1) {
          this.$refs.vid2.load();
          this.$refs.vid2.play();
        } else if (this.questionIndex === 3) {
          this.$refs.vid3.load();
          this.$refs.vid3.play();
        }
        this.questionIndex++;
      }

      if (this.questionIndex === 3) {
        this.$refs.vid1.currentTime = 0;
        this.videoIdx = 1;

        // console.log("this.userResponses");
        // console.dir(this.userResponses);

        // console.log("this.questions");
        // console.dir(this.questions);

        //zapis wyników
        setTimeout(() => {
          const result = {
            lang: this.$i18n.locale,
            quizKey: window.Quiz.key,
            userName: this.userName,
            userResponses: this.userResponses,
          };

          axios
            .post(`${window.Quiz.apiEndpoint}`, result)
            .then(() => {
              //console.dir(response);
            })
            .catch((error) => {
              this.error = error.message || "Something failed!";
            });
        }, 200);
      }
    },
    prev: function () {
      if (this.questions.length > 0 && this.questionIndex >= 0) this.questionIndex--;
    },

    onCopy: function () {
      this.tooltip = this.$i18n.t("quiz.justCopied");

      setTimeout(() => {
        this.tooltip = null;
      }, 3000);
    },

    watchVideoAgain() {
      this.$refs.vid1.currentTime = 0;
      this.videoIdx = 1;
      this.$refs.vid1.play();
      this.$refs.vid2.play();
      this.$refs.vid3.play();
    },
    onError: () => {
      console.log("Failed to copy texts");
    },
    localizeQuestion() {
      this.questions = [
        {
          id: 1,
          text: this.$i18n.t("quiz.questions.q2"),
          responses: [
            { id: 1, text: this.$i18n.t("quiz.answers.q2-opt1"), img: "answer-2-true-love.png", movie: "answer-2-true-love.mp4" },
            { id: 2, text: this.$i18n.t("quiz.answers.q2-opt2"), img: "answer-2-find-peace.png", movie: "answer-2-find-peace.mp4" },
            { id: 3, text: this.$i18n.t("quiz.answers.q2-opt3"), img: "answer-2-my-own-start-up.png", movie: "answer-2-my-own-start-up.mp4" },
            { id: 4, text: this.$i18n.t("quiz.answers.q2-opt4"), img: "answer-2-more-adventure.png", movie: "answer-2-more-adventure.mp4" },
            { id: 5, text: this.$i18n.t("quiz.answers.q2-opt5"), img: "answer-2-the-big-stage.png", movie: "answer-2-the-big-stage.mp4" },
            { id: 6, text: this.$i18n.t("quiz.answers.q2-opt6"), img: "answer-2-make-it-to-the-top.png", movie: "answer-2-make-it-to-the-top.mp4" },
            { id: 7, text: this.$i18n.t("quiz.answers.q2-opt7"), img: "answer-2-an-amazing-family.png", movie: "answer-2-an-amazing-family.mp4" },
            { id: 8, text: this.$i18n.t("quiz.answers.q2-opt8"), img: "answer-2-true-friendship.png", movie: "answer-2-true-friendship.mp4" },
            { id: 9, text: this.$i18n.t("quiz.answers.q2-opt9"), img: "answer-2-retire-next-year.png", movie: "answer-2-retire-next-year.mp4" },
            { id: 10, text: this.$i18n.t("quiz.answers.q2-opt10"), img: "answer-2-make-a-diference.png", movie: "answer-2-make-a-diference.mp4" },
          ],
        },
        {
          id: 2,
          text: this.$i18n.t("quiz.questions.q1"),
          responses: [
            { id: 1, text: this.$i18n.t("quiz.answers.q1-opt1"), img: "answer-1-fights.png", movie: "answer-1-fights.mp4" },
            { id: 2, text: this.$i18n.t("quiz.answers.q1-opt2"), img: "answer-1-stage-fright.png", movie: "answer-1-stage-fright.mp4" },
            { id: 3, text: this.$i18n.t("quiz.answers.q1-opt3"), img: "answer-1-hypocrisy.png", movie: "answer-1-hypocrisy.mp4" },
            { id: 4, text: this.$i18n.t("quiz.answers.q1-opt4"), img: "answer-1-toxic-relationships.png", movie: "answer-1-toxic-relationships.mp4" },
            { id: 5, text: this.$i18n.t("quiz.answers.q1-opt5"), img: "answer-1-falling.png", movie: "answer-1-falling.mp4" },
            { id: 6, text: this.$i18n.t("quiz.answers.q1-opt6"), img: "answer-1-loneliness.png", movie: "answer-1-loneliness.mp4" },
            { id: 7, text: this.$i18n.t("quiz.answers.q1-opt7"), img: "answer-1-social-pressure.png", movie: "answer-1-social-pressure.mp4" },
            { id: 8, text: this.$i18n.t("quiz.answers.q1-opt8"), img: "answer-1-overwork.png", movie: "answer-1-overwork.mp4" },
            { id: 9, text: this.$i18n.t("quiz.answers.q1-opt9"), img: "answer-1-break-ups.png", movie: "answer-1-break-ups.mp4" },
            { id: 10, text: this.$i18n.t("quiz.answers.q1-opt10"), img: "answer-1-boredom.png", movie: "answer-1-boredom.mp4" },
          ],
        },
        {
          id: 3,
          text: this.$i18n.t("quiz.questions.q3"),
          responses: [
            { id: 1, text: this.$i18n.t("quiz.answers.q3-opt1"), img: "answer-3-world-tour-on-a-scooter.png", movie: "answer-3-world-tour-on-a-scooter.mp4" },
            { id: 2, text: this.$i18n.t("quiz.answers.q3-opt2"), img: "answer-3-run-a-marathon.png", movie: "answer-3-run-a-marathon.mp4" },
            { id: 3, text: this.$i18n.t("quiz.answers.q3-opt3"), img: "answer-3-became-famous.png", movie: "answer-3-became-famous.mp4" },
            { id: 4, text: this.$i18n.t("quiz.answers.q3-opt4"), img: "answer-3-write-comedy.png", movie: "answer-3-write-comedy.mp4" },
            { id: 5, text: this.$i18n.t("quiz.answers.q3-opt5"), img: "answer-3-swim-in-all-oceans.png", movie: "answer-3-swim-in-all-oceans.mp4" },
            {
              id: 6,
              text: this.$i18n.t("quiz.answers.q3-opt6"),
              img: "answer-3-play-with-my-favourite-band.png",
              movie: "answer-3-play-with-my-favourite-band.mp4",
            },
            { id: 7, text: this.$i18n.t("quiz.answers.q3-opt7"), img: "answer-3-travel-in-space.png", movie: "answer-3-travel-in-space.mp4" },
            { id: 8, text: this.$i18n.t("quiz.answers.q3-opt8"), img: "answer-3-live-in-my-dream-city.png", movie: "answer-3-live-in-my-dream-city.mp4" },
            { id: 9, text: this.$i18n.t("quiz.answers.q3-opt9"), img: "answer-3-bungee-jump.png", movie: "answer-3-bungee-jump.mp4" },
            { id: 10, text: this.$i18n.t("quiz.answers.q3-opt10"), img: "answer-3-go-on-a-safari.png", movie: "answer-3-go-on-a-safari.mp4" },
          ],
        },
      ];
    },
    closeInfo() {
      this.showInfo = false;
    },

    videoPlay(id) {
      if (id === 1) {
        this.$refs.vid1.play();
      } else if (id === 2) {
        this.$refs.vid2.play();
      } else if (id === 3) {
        this.$refs.vid3.play();
      }
    },

    videoEnded1() {
      console.log("videoEnded1");

      this.$refs.vid1.play();
      var playPromise = this.$refs.vid1.play();
      if (this.videoIdx === 1) {
        this.$refs.vid2.currentTime = 0;
        this.videoIdx = 2;

        if (playPromise !== undefined) {
          playPromise
            .then(() => {
              this.$refs.vid1.pause();
            })
            .catch(() => {});
        }
      }
    },
    videoEnded2() {
      console.log("videoEnded2");
      var playPromise = this.$refs.vid2.play();
      if (this.videoIdx === 2) {
        this.$refs.vid3.currentTime = 0;
        this.videoIdx = 3;
        if (playPromise !== undefined) {
          playPromise
            .then(() => {
              this.$refs.vid2.pause();
            })
            .catch(() => {});
        }
      }
    },
    videoEnded3() {
      console.log("videoEnded3");
      var playPromise = this.$refs.vid3.play();
      if (this.videoIdx === 3) {
        this.videoIdx = 4;
        if (playPromise !== undefined) {
          playPromise
            .then(() => {
              this.$refs.vid3.pause();
            })
            .catch(() => {});
        }
      }
    },
  },
  created() {
    this.localizeQuestion();
    this.restart();
  },
  unmounted() {},
};
</script>
